// fonts

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  src: url(assets/fonts/NunitoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url(assets/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url(assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  src: url(assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

// Base
@import 'app/shell/styles/normalize.css';
@import 'app/shell/styles/defaults.less';

// Library less files
@import 'app/shell/styles/vars.less';
@import 'app/shell/styles/actions.less';
@import 'app/shell/styles/avatars.less';
@import 'app/shell/styles/app-bar.less';
@import 'app/shell/styles/badges.less';
@import 'app/shell/styles/buttons.less';
@import 'app/shell/styles/button-groups.less';
@import 'app/shell/styles/box.less';
@import 'app/shell/styles/box-model.less';
@import 'app/shell/styles/cards.less';
@import 'app/shell/styles/chips.less';
@import 'app/shell/styles/container.less';
@import 'app/shell/styles/content.less';
@import 'app/shell/styles/divider.less';
@import 'app/shell/styles/grid.less';
@import 'app/shell/styles/inputs.less';
@import 'app/shell/styles/form.less';
@import 'app/shell/styles/layout.less';
@import 'app/shell/styles/typography.less';
@import 'app/shell/styles/modal.less';
@import 'app/shell/styles/images.less';
@import 'app/shell/styles/cards.less';
@import 'app/shell/styles/groups.less';
@import 'app/shell/styles/lists.less';
@import 'app/shell/styles/tables.less';
@import 'app/shell/styles/lozenges.less';
@import 'app/shell/styles/external-branding.less';

// Discover Custom styles
@import 'app/shell/styles/panel.less';
@import 'app/shell/styles/console-form-list.less';
@import 'app/shell/styles/print.less';