@import '../../../../../../node_modules/@unifii/library/styles/form.less';


// TODO Remove stepper implemented properly in library

.stepper-form {
    > .uf-app-bar {
        display: none;
    }

    uf-form h3 {
        text-align: center;
    }

    uf-form-group {
        .grid {
            border-bottom-style: none;
        }
    }

    uf-survey {
        box-shadow: @box-shadow-lg;

        .form-group-content {
            border-bottom-style: none;
        }
    }

    uf-workflow {
        display: none;
    }

    .uf-field--content {
        padding-left: 0;
        padding-right: 0;
    }

    uf-step .actions {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
} 

