@import '../../../../../../node_modules/@unifii/library/styles/images';

div[class*='ud-image'] {
    position: relative;
    clear: both;
    background-color: @gray-5;

    img,
    uf-icon {
        display: block;
        margin: 0 auto;

        position: relative;
        width: 100%;

        padding: @space-3;
    }

    img[src=""] {
        visibility: hidden;
    }

    uf-icon {
        height: 96px;
        width: 96px;
    }

    &[class*='--no-padding'] {
        img {
            padding: 0;
        }
    }

    &[class*='--transparent'] {
        background: none;
    }

    // fixed ratios
    &[class*='--fixed-ratio'] {
        padding-top: 100%;

        img,
        uf-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            max-width: 100%;
            max-height: 100%;

            margin: auto;
        }

        img {
            width: auto;
        }

        // 16 by 9 images
        &[class*='--16by9'] {
            padding-top: 56.25%;
        }

        // 4 by 3 images
        &[class*='--4by3'] {
            padding-top: 75%;
        }

        // 3 by 2 images
        &[class*='--3by2'] {
            padding-top: 66.66%;
        }
    }

    &.right {
        float: right;
        margin-left: @gutter;
        padding: 0;
    }

    &.left {
        float: left;
        margin-right: @gutter;
        padding: 0;
    }
}

