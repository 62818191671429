// ------------------------------------------
//              Disable user select
// ------------------------------------------

.block-user-select() {
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
}

// ------------------------------------------
//             Layout
// ------------------------------------------

.container-stretched() {
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
}

// Container class for stretched overlays
// contains safe-area-inset-top which is required for mobile apps
.overlay-container() {
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    bottom: 0;
    right: 0;
}