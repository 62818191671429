// todo: remove when forms are migrated to pdf renderer

@page {
    size: A4;
    margin: 0;
}

@media print {
    uf-form {
        width: 21cm;
        margin: auto;
        page-break-after: always;
        
        font-size: 12px;
    }
}

