
@keyframes dot-keyframes {
    0% {
        opacity: .4;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: .4;
    }
}

.loading-dots {
    text-align: center;
    position: fixed;
    margin: 3rem auto auto auto;
    width: 100%;
}

.dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #999999;
    border-radius: .5rem;
    display: inline-block;
    height: .5rem;
    width: .5rem;
    margin: 0 2px;
}

.dot:nth-child(2) {
    animation-delay: .5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@media print {
    .loading-dots {
        display: none;
    }
}

