@import '../../../../../../node_modules/@unifii/library/styles/vars';

.ud-group--shadow--no-border {
    .ud-group-content {
        border: 0;
    }
}

.ud-group--shadow,
.ud-group--shadow--no-border {
    box-shadow: @box-shadow-lg;
}

// Required when group with shadow is used with grid
.ud-group--shadow--no-border.col-1of1 {
    padding-left: 0;
    padding-right: 0;
    margin-right: @space-3;
    margin-left: @space-3;
    margin-bottom: @gutter;
}

//---------------------------------------
//          Header


.ud-header--accent {
    display: flex;
    padding: 0 @space-2;

    >*:not(uf-expander-button) {
        margin: auto @space-2;
    }

    uf-expander-button {
        margin: auto 0 auto @space-2;
    }

    // title
    .title,
    .title--primary,
    .title--clickable {
        flex-grow: 1;

        color: inherit;
        font-family: @font-family-secondary;
        font-weight: 700;

        &:first-child {
            margin-left: @gutter * .75;
        }
    }

    .title--primary,
    .title--clickable--primary,
    .title--primary--clickable {
        font-family: @font-family;
        font-weight: normal;
    }

    .title--clickable,
    .title--primary--clickable,
    .title--clickable--primary {
        cursor: pointer;
    }

    // Actions
    .uf-action,
    .uf-action.small,
    .uf-action.large,
    .uf-action.primary,
    // Primary
    .uf-action.primary,
    .uf-action.small.primary,
    .uf-action.large.primary,
    .uf-action.primary.small,
    .uf-action.primary.large,
    // Tertiary
    .uf-action.tertiary,
    .uf-action.small.tertiary,
    .uf-action.large.tertiary,
    .uf-action.small.tertiary,
    .uf-action.large.tertiary {

        background: transparent;

        uf-icon {
            fill: @gray-80;
        }

        &:hover:not(:disabled) {
            background: fade(black, 5%);
        }

        &:active:not(:disabled),
        &:focus:not(:disabled) {
            background: fade(black, 10%);
        }

        &:before {
            content: initial;
        }

    }

}

// Accent
.ud-header--accent {

    background: @brand-accent;

    color: white;

    .uf-action,
    .uf-action.small,
    .uf-action.large,
    .uf-action.primary,
    // Primary
    .uf-action.primary,
    .uf-action.small.primary,
    .uf-action.large.primary,
    .uf-action.primary.small,
    .uf-action.primary.large,
    // Tertiary
    .uf-action.tertiary,
    .uf-action.small.tertiary,
    .uf-action.large.tertiary,
    .uf-action.small.tertiary,
    .uf-action.large.tertiary {

        uf-icon {
            fill: white;
        }
    }

    uf-icon {
        fill: white;
    }

}

.ud-header--accent {

    .title,
    .title--primary {
        font-size: @font-size;
        line-height: @line-height;
    }
}


.ud-header--accent {
    min-height: @app-bar-height-sm;

    .title,
    .title--primary {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }

}

