@import (reference) './vars.less';
@import (reference) './mixins.less';

html {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    background-color: black;

    font-size: 16px;
    font-variant-ligatures: none;
}

body {
    margin: 0;
    position: fixed;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    top: env(safe-area-inset-top);
    bottom: 0px;

    // styles
    background-color: white;

    // text
    font-family: @font-family;
    color: @gray-80;
    font-size: @font-size;
    line-height: @line-height;
}

us-app,
us-view d-main {
    .container-stretched;
}

[hidden] {
    display: none;
}

@media print {

    html,
    body,
    us-app,
    d-main,
    us-view,
    .scrollable {
        position: static;
        overflow: visible;

        font-size: 12px;
    }

    us-header,
    us-menu-drawer,
    right-menu,
    uf-progress {
        display: none !important;
    }

    .push-down {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }

}


//---------------------------------------
//          Selection
* {
    -webkit-tap-highlight-color: transparent;
}

::selection {
    background-color: @gray-10;
}

::-moz-selection {
    background-color: @gray-10;
}

[tabindex] {
    outline-color: @gray-100;
}

//---------------------------------------
//          Safari Mobile
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
}

::-webkit-scrollbar {
    width: @space-2;
    height: @space-4;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: @space-2;
    background-color: @gray-10;
}

::-webkit-scrollbar-button {
    height: 0px;
}

::-webkit-scrollbar-track-piece {
    background-color: @gray-5;
}