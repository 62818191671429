@import '../../../../../../node_modules/@unifii/library/styles/layout.less';

// ---------------------------------------------------
// Dead simple helpers for ad-hoc flexing
// Do not add things here unless it is super generic
// Season with vanilla flex CSS for desired results
// ---------------------------------------------------

/** 

    Reference 

    #flex helpers
    
    .row;
    .col;
    .grow;
    .shrink;
    .stretch;
    .center;
    .center-all;
    .justify-center;


    #margins padding

    .padded;
    .padded--big;
    .padded--small;
    .padded--vertical;
    .padded--horizontal;
    .margin;
    .margin--vertical;
    .margin--horizontal;

*/

.left {
    margin-right: auto;
}

.right {
    margin-left: auto;
}