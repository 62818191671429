@import '../../../../../../node_modules/@unifii/library/styles/cards.less';


.ud-card-mixin() {
    display: flex;
    flex-direction: column;

    background: white;
    box-shadow: @box-shadow;

    &[class*='--centered'] {
        text-align: center;

        .fieldset-label {
            flex: 1;
            text-align: left;
        }

        .fieldset-value {
            flex: 1;
            text-align: right;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    >uf-panel {
        flex: 1;
    }

    .header {
        padding: 0 @gutter;

        *[class*='uf-action'] {
            float: right;

            &:first-of-type {
                margin-right: -@gutter;
            }
        }
    }

    .content {
        padding: 0 @gutter;
    }

    .btns {
        display: flex;
        justify-content: flex-end;

        margin-left: auto;
        padding: @gutter;

        min-height: @gutter*4;

        >* {
            margin-left: @gutter;
        }
    }
}

//-------------------------------------
//             Default Card

.ud-card {
    .ud-card-mixin();

    overflow: hidden;
    border-radius: @border-radius;
}



//-------------------------------------
//             Card actions

// Can be used at top and bottom of card

.ud-card-actions{
    float: right;

    >* {
        float: right;
    }

    >.uf-action.small.tertiary,
    >.uf-action.tertiary,
    >.uf-action.large {
        margin-top: -@space-3;
        margin-right: -@space-3;
    }

    >*[class*='uf-button'] {
        margin-left: @space-3;

        &:first-child {
            margin-right: @space-3;
        }
    }
}

// Align heading if actions first child

.col-1of1 {

    &>.ud-card-actions + h1,
    &>.ud-card-actions + h2,
    &>.ud-card-actions + h3,
    &>.ud-card-actions + h4,
    &>.ud-card-actions + h5,
    &>.ud-card-actions + h6 {
        margin-top: 0.5rem;
    }
}


//-------------------------------------
//             Form Card

// was originally content card

.ud-form-card {
    .ud-card-mixin();

    position: relative;

    &:before {
        content: '';
        display: block;

        border-top: @space-2 solid;
        border-top-color: @brand-accent;
    }

    uf-progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

//-------------------------------------
//            Deprecated


a.ud-product-card--centered {
    text-decoration: none;
    color: @text-color;

    transition: all .1s ease-in-out;

    &:hover {
        box-shadow: @box-shadow-lg;
    }

    &[class*='--placeholder'] {
        opacity: 0;

        &:hover {
            opacity: 0.5;
            box-shadow: @box-shadow;
        }
    }
}

// Deprecated use ud-card

.ud-product-card--centered {
    .ud-card-mixin();

    border-radius: @border-radius;
    overflow: hidden;
}

.ud-card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: @gutter*2;
    align-items: stretch;

    padding-right: @gutter*2;
    padding-left: @gutter*2;
}

.ud-product-card-label {
    display: flex;
    min-height: @gutter*3.5rem;
    align-items: center;
    justify-content: center;
    padding: 0 @gutter;

    h4 {
        margin: 0;
    }
}

@media (max-width: @mobile-screen) {

    .ud-card-list {
        grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
    }

    .ud-product-card-label h4 {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }

}

