@import '../../../../../../node_modules/@unifii/library/styles/vars';
@import '../../../../../../node_modules/@unifii/library/styles/lists';


// Override for table page possibly could be added to core as a list style

@media screen and (max-width: @screen-width-md) {

    .table-page uf-table-container.list-md {
        .uf-list.uf-box {
            border-top: 2px solid @brand-accent;

            li {
                border-bottom: 2px solid @brand-accent;
            }
        }

    }
}