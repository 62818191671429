@import (reference) 'vars.less';

/**
layouts are based on the order that they are place within the view
a few simple cases are handled

Simple layout
.header-pane
.content-pane

Secondary Nav layout
.header-pane--bordered
.nav-pane
.content-pane

*/

.panel {
    .container-stretched();
}

/**
Default solid background colour
needed child routes that overlay parent components
*/
uf-panel.bg-solid {
    --panel-background-colour: white;
}

uf-panel.bg-gray {
    --panel-background-colour: @gray-5;
}

.uf-container.bg-solid,
.uf-container-sm.bg-solid,
.uf-container-md.bg-solid,
.uf-container-lg.bg-solid,
.uf-container-xl.bg-solid {
    background: white;
}

/** header */

.header-pane,
.header-pane--bordered {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    min-height: @app-bar-height;
    align-items: center;
    padding: 0 @gutter;

    >*:not(:first-child) {
        margin-left: @space-3;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    >uf-breadcrumbs {
        flex-grow: 1;
    }
}

.header-pane--bordered {
    border-bottom: 1px solid @gray-20;
}


/** Secondary navigation */

.header-pane+.nav-pane,
.header-pane--bordered+.nav-pane {
    /** todo: figthing uf-panel position relative */
    position: absolute;
    top: @app-bar-height;
}

.nav-pane {
    .container-stretched();
    width: 15.75rem;

    .link-list {
        list-style: none;
        padding: 0;
        margin: @space-3;

        a {
            display: block;
            padding: @space-3;

            border-radius: @border-radius;

            text-decoration: none;
            font-size: @font-size-sm;
            line-height: @line-height-sm;
            color: @text-color;

            &.active {
                background: @primary-action;
                color: white;
            }

            &:hover:not(.active) {
                background: @gray-10;
            }
        }
    }
}


/** Content */

.nav-pane+.content-pane {
    .container-stretched();
    top: @app-bar-height;
    left: 15.75rem;
    margin: @space-3 @gutter @space-3 @space-3;
}

.content-pane {
    margin: @gutter;
}

.header-pane+.content-pane {
    margin-top: 0;
}

div.master-detail,
uf-panel.master-detail {
    display: none;
}

@media (max-width: 768px) {

    .header-pane>uf-search {
        width: 100%;
    }

    .header-pane--bordered {
        border-bottom: none;
    }

    .nav-pane {
        display: none;
    }

    .nav-pane+.content-pane {
        left: 0;
    }
}