ul.ud-form-list {

    width: 100%;
    margin: 0;
    padding: 0;

    li {

        display: flex;
        align-items: center;
        padding: @gutter;

        border-bottom: 1px solid @gray-20;

        >*:not(:last-child) {
            margin-right: @gutter;
        }

        .input-row {
            display: flex;

            *:not(:last-child) {
                margin-right: @gutter;
            }

        }
    }
}

@media only screen and (max-width: @mobile-screen) {

    ul.ud-form-list {
        li {
            .input-row {
                flex-direction: column;

                *:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: @gutter;
                }
            }
        }
    }

}